<template>
  <dl class="px-4 space-y-3 sm:px-6">
    <div class="flex items-center justify-between">
      <dt class="text-sm">Subtotal</dt>
      <dd class="text-sm font-medium text-gray-900">$ {{ costs.subtotal.toFixed(2) }}</dd>
    </div>
    <div class="flex items-center justify-between" v-if="showShipping">
      <dt class="text-sm">Shipping</dt>
      <dd class="text-sm font-medium text-gray-900">$ {{ costs.shipping.toFixed(2) }}</dd>
    </div>
    <div class="flex items-center justify-between" v-if="costs.discount">
      <dt class="text-sm">Discount</dt>
      <dd class="text-sm font-medium text-gray-900">- $ {{ costs.discount.toFixed(2) }}</dd>
    </div>
    <div class="flex items-center justify-between" v-if="storeCredit">
      <dt class="text-sm">Store Credit</dt>
      <dd class="text-sm font-medium text-gray-900">- $ {{ storeCredit.toFixed(2) }}</dd>
    </div>
    <div class="flex items-center justify-between">
      <dt class="text-sm">Taxes</dt>
      <dd class="text-sm font-medium text-gray-900">$ {{ costs.tax.toFixed(2) }}</dd>
    </div>
    <div class="flex items-center justify-between border-t border-gray-200 pt-3">
      <dt class="text-base font-medium">Total</dt>
      <dd class="text-base font-medium text-gray-900">$ {{ costs.total.toFixed(2) }}</dd>
    </div>
  </dl>
</template>

<script>
import calculateCosts from '@/assets/functions/calculateCosts';
export default {
  props: {
    products: Array,
    shipping: { default: 0, type: Number },
    discount: { default: 0, type: Number },
    taxRate: { default: 0.0825, type: Number },
    showShipping: { default: true, type: Boolean },
    storeCredit: { default: 0, type: Number },
  },
  data() {
    return {};
  },
  computed: {
    costs: {
      get() {
        return calculateCosts(
          this.products,
          this.taxRate,
          this.shipping,
          this.discount,
          this.storeCredit
        );
      },
    },
  },
};
</script>

<style></style>
